<template lang="pug">
analytics-company(
  tab="AnalyseSuppliers"
  placeholder="Поиск поставщика (начните вводить наименование, ИНН или адрес поставщика и выберите из списка)"
  :tabs="suppliersTabs"
  :average-cards="averageCards"
  :filters="advancedFilters"
)
</template>

<script lang="ts">
import { defineComponent, provide } from "vue";
import AnalyticsCompany from "@/components/pages/analytics/CompanyPage.vue";

export default defineComponent({
  name: "AnalyticsSupplier",
  components: {
    AnalyticsCompany,
  },
  setup() {

    const averageCards = [
      [{ label: "Общее количество участий", valueNum: "total", compact: true }],
      [{ label: "Общее количество побед", valueNum: "win", compact: true }],
      [{ label: "Процент побед", valueNum: "winPercent", valueStr: "%", digits: true }],
      [{ label: "Общая сумма побед", valueNum: "winSummary", compact: true, currency: true, }],
      [{ label: "Средняя сумма победы", valueNum: "avgWinCost", compact: true, currency: true, }],
    ];

    const suppliersTabs = [
      { title: "Победил", mode: "won" },
      { title: "Участвовал", mode: "participated" },
      { title: "Заказчики", mode: "customers" },
      { title: "Конкуренты", mode: "competitors" },
    ];

    const advancedFilters = [
      'LawSiteKpp',
      'DatePublicAndType',
      'PriceAndPaymentTerms',
      'CategoryOkpdAndCustomers',
    ];

    provide("type", "AnalyseSuppliers");

    return {
      averageCards,
      suppliersTabs,
      advancedFilters,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
